<template>
  <b-modal id="modal" size="md" :title="data.name"
           @close="back()"
           no-close-on-esc
           no-close-on-backdrop>

    <div v-if="data" autocomplete="off">
      <b-row>
        <b-form-group class="col-12">
          <label>{{$t('users.name')}}</label>
          <b-form-input
              v-model="data.name"
              type="text"
          ></b-form-input>
        </b-form-group>
        <b-form-group class="col-12">
          <label>{{$t('users.email')}}</label>
          <b-form-input
              v-model="data.email"
              type="email"
              placeholder="@"
              autocomplete="new-password"
              required
          ></b-form-input>
        </b-form-group>
        <b-form-group class="col-12">
          <label>{{$t('users.tel')}}</label>
          <b-form-input
              autocomplete="new-password"
              v-model="data.tel"
              type="text"
          ></b-form-input>
        </b-form-group>

        <b-form-group v-if="user.permission === 'admin'" class="col-12">
          <label>{{$t('users.permissions')}}</label>
          <b-form-select v-model="data.permission">
            <b-form-select-option v-for="item in permissionsOptions" :key="item.value" :value="item.value">
              {{$t('users.role.'+item.value)}}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-row>

      <b-button
          v-if="!newAccount"
          :class="visiblePsw ? null : 'collapsed'"
          :aria-expanded="visiblePsw ? 'true' : 'false'"
          aria-controls="collapse-psw"
          @click="changePswdToggle"
          variant="primary" size="sm">
        {{$t('users.changePswd')}}
      </b-button>
      <b-collapse id="collapse-psw" v-model="visiblePsw" class="my-2">
        <b-card>
          <b-form-group>
            <label>{{$t('users.newPswd')}}</label>
            <b-form-input
                v-model="password"
                type="password"
                autocomplete="off"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label>{{$t('users.newPswdControl')}}</label>
            <b-form-input
                v-model="password2"
                type="password"
                autocomplete="off"
            ></b-form-input>
          </b-form-group>
        </b-card>
      </b-collapse>
      <b-form-group class="text-right">
        <b-button variant="danger" @click="deleteItem(data.id)">{{$t('universalTxt.delete')}}
          <b-icon icon="trash-fill"></b-icon>
        </b-button>
      </b-form-group>
    </div>
    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button @click="back()" class="float-left" form="form1" variant="outline-secondary">
          {{$t('universalTxt.closeBtn')}}
        </b-button>
        <b-button @click="save()" class="float-right" form="form1" variant="primary">
          {{$t('universalTxt.saveBtn')}}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "users-edit",
  data() {
    return {
      data: {},
      changePsw: false,
      password: '',
      password2: '',
      visiblePsw: false,
      newAccount: false
    }
  },
  methods: {
    changePswdToggle() {
      this.visiblePsw = !this.visiblePsw;
      this.password = "";
      this.password2 = "";
    },

    async loadData() {
      try {
        const response = await this.$http({
          url: process.env.VUE_APP_SERVER + 'api/users/users.php',
          headers: {'content-type': 'application/x-www-form-urlencoded'},
          params: {'id': this.$route.params.id},
          method: 'GET'
        });

        this.data = response.data.records[0];
        if (this.data.email === '') {
          this.newAccount = true;
          this.visiblePsw = true;
        }

      } catch (error) {
        console.log("error", error);
        this.$eventHub.$emit('openMessageController', 'error', 'No data');
      }

    },

    async save() {

      if (this.visiblePsw) {
        if (this.password2 !== '') {
          if ( this.password2 === this.password ) {
            this.data.password = this.password;
            this.data.change_pswd = 1;
            await this.update();
          } else {
            this.$eventHub.$emit('openMessageController', 'error', this.$t('messages.pswd_not_same'));
          }
        } else {
          this.$eventHub.$emit('openMessageController', 'error', this.$t('messages.pswd_empty'));
        }
      } else {
        await this.update();
      }
    },

    async update() {
      try {
        await this.$http({
          url: process.env.VUE_APP_SERVER + 'api/users/update-user.php',
          headers: {'content-type': 'application/x-www-form-urlencoded'},
          data: this.data,
          method: 'PUT'
        });

        this.$emit('loadData');
        this.$bvModal.hide('modal');
        this.$router.go(-1);
        this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.success'));
      } catch (error) {
        console.log("error", error);
        this.$eventHub.$emit('openMessageController', 'error', this.$t('messages.error'));
      }
    },

    async deleteItem(id) {
      try {
        if (confirm(this.$t('projects.deleteProject'))) {
          await this.$http({
            url: process.env.VUE_APP_SERVER + 'api/users/remove-item.php',
            headers: {'content-type': 'application/x-www-form-urlencoded'},
            data: {'id': id},
            method: 'DELETE'
          });
          this.$emit('loadData');
          this.$bvModal.hide('modal');
          this.$router.go(-1);
          this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.deleted'));
        }

      } catch (error) {
        await this.loadData();
        console.log("error", error);
        this.$eventHub.$emit('openMessageController', 'error', this.$t('messages.error'));
      }

    },
    back() {
      this.$emit('loadData');
      this.$router.go(-1);
    }

  },
  computed: {
    permissionsOptions: function () {
      return this.$store.getters.getUserRoles;
    },
    user: function () {
      return this.$store.getters.user;
    }
  },
  mounted() {
    this.$bvModal.show('modal');
    this.loadData();
  },
  created() {

  }
}
</script>

<style scoped>

</style>
